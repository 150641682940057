import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import OfficesIMG from "./../../Global/images/officesIMG.jpg";

const StyledOfficesBlock = styled(NanoFlex)`
  .asideAddress {
    background: linear-gradient(180deg, #e3e3e3 85%, #ffffff 85%);
    padding: 56px;
    .titleWrapper {
      width: 80%;
      margin-bottom: 32px;
      .titleAside {
        margin-bottom: 32px;
        h2 {
          b {
            color: ${(props) => props.theme.color.main.blue};
          }
        }
      }
    }

    .addressesList {
      height: auto;
      .addressItem {
        gap: 20px;
        max-width: 384px;
        padding: 24px;
        background-color: ${(props) => props.theme.color.main.blue};

        margin-bottom: 24px;
        cursor: pointer;
        .officeName {
          margin-bottom: 16px;
          p {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.white};
            /* color: ${(props) => props.theme.color.main.font}; */
          }
        }
        .officeAddress {
          margin-bottom: 16px;
          display: none;
        }
        .officePhone {
          margin-bottom: 16px;
          display: none;
        }
        .officeEmail {
          a {
            text-decoration: none;
            p {
              text-transform: uppercase;
              color: ${(props) => props.theme.color.main.white};
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          -webkit-box-shadow: 6px 0px 21px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 6px 0px 21px rgba(0, 0, 0, 0.25);
          box-shadow: 6px 0px 21px rgba(0, 0, 0, 0.25);
          transition: ${(props) => props.theme.transition};
        }
      }

      .selectedAddress {
        background-color: ${(props) => props.theme.color.main.white};
        transition: ${(props) => props.theme.transition};
        pointer-events: none;
        .officeName {
          p {
            color: ${(props) => props.theme.color.main.font};
          }
        }
        .officeAddress {
          margin-bottom: 16px;
          display: flex;
          p {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.font};
          }
        }
        .officePhone {
          margin-bottom: 16px;
          display: flex;
          p {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.font};
          }
        }
        .officeEmail {
          a {
            p {
              color: ${(props) => props.theme.color.main.font};
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .asideImage {
    height: auto;
    .imageWrapper {
      height: 85%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1074px) {
    .asideImage {
      display: none;
    }
  }
  @media only screen and (max-width: 754px) {
    .asideAddress {
      .titleWrapper {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .asideAddress {
      padding: 24px;
      background: linear-gradient(180deg, #e3e3e3 90%, #ffffff 90%);
      .titleWrapper {
        text-align: center;
        margin-bottom: 16px;
        .titleAside {
          margin-bottom: 16px;
          h2 {
            font-size: 40px;
            line-height: inherit;
          }
        }
      }
      .addressesList {
        .addressItem {
          margin-bottom: 16px;
        }
      }
    }
  }
`;

export default class OfficesBlock extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedAddress: 0,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledOfficesBlock className="officesBlocks" justifyContent="stretch" alignItems="stretch" alignContent="stretch">
        <NanoFlex className="asideAddress" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="titleWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="titleAside" justifyContent="flex-start">
              <Type.h2>
                <b>{this.translation.homepage.officesBlock.title}</b>
              </Type.h2>
            </NanoFlex>
            <NanoFlex className="subtitleAside" justifyContent="flex-start">
              <Type.h5>{this.translation.homepage.officesBlock.subtitle}</Type.h5>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="addressesList" flexDirection="column">
            {/* ADDRESS ITEM - LISBOA */}
            <NanoFlex className={`addressItem ${this.state.selectedAddress == 0 ? "selectedAddress" : ""}`} flexDirection="column" justifyContent="flex-start" alignItems="flex-start" onClick={() => this.setState({ selectedAddress: 0 })}>
              <NanoFlex className="addressBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="officeName" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.translation.homepage.officesBlock.lisbon.name}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="officeName" justifyContent="flex-start"></NanoFlex>
                {this.state.selectedAddress != 0 && (
                  <NanoFlex className="officeEmail" justifyContent="flex-start">
                    <a href={`mailto:${this.translation.homepage.officesBlock.lisbon.portugalEmail}`}>
                      <Type.p>{this.translation.homepage.officesBlock.lisbon.portugalEmail}</Type.p>
                    </a>
                  </NanoFlex>
                )}
                {this.state.selectedAddress == 0 && (
                  <NanoFlex className="officeName" justifyContent="flex-start">
                    <Type.p>
                      <b>{this.translation.homepage.officesBlock.lisbon.subName}</b>
                    </Type.p>
                  </NanoFlex>
                )}
                <NanoFlex className="officeAddress" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <Type.p>{this.translation.homepage.officesBlock.lisbon.address}</Type.p>
                  <Type.p>{this.translation.homepage.officesBlock.lisbon.addressExtraInfo}</Type.p>
                  <Type.p>{this.translation.homepage.officesBlock.lisbon.postalCode}</Type.p>
                  <Type.p>{this.translation.homepage.officesBlock.lisbon.country}</Type.p>
                </NanoFlex>
                {this.state.selectedAddress == 0 && (
                  <NanoFlex className="officeEmail" justifyContent="flex-start">
                    <a href={`mailto:${this.translation.homepage.officesBlock.lisbon.email}`}>
                      <Type.p>{this.translation.homepage.officesBlock.lisbon.email}</Type.p>
                    </a>
                  </NanoFlex>
                )}
                <NanoFlex className="officeName" justifyContent="flex-start"></NanoFlex>
              </NanoFlex>
              {this.state.selectedAddress == 0 && (
                <NanoFlex className="addressBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <NanoFlex className="officeName" justifyContent="flex-start">
                    <Type.p>
                      <b>{this.translation.homepage.officesBlock.oporto.name}</b>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="officeAddress" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.translation.homepage.officesBlock.oporto.address}</Type.p>
                    <Type.p>{this.translation.homepage.officesBlock.oporto.addressExtraInfo}</Type.p>
                    <Type.p>{this.translation.homepage.officesBlock.oporto.postalCode}</Type.p>
                    <Type.p>{this.translation.homepage.officesBlock.oporto.country}</Type.p>
                  </NanoFlex>
                  <NanoFlex className="officeEmail" justifyContent="flex-start">
                    <a href={`mailto:${this.translation.homepage.officesBlock.oporto.email}`}>
                      <Type.p>{this.translation.homepage.officesBlock.oporto.email}</Type.p>
                    </a>
                  </NanoFlex>
                </NanoFlex>
              )}
            </NanoFlex>

            {/* ADDRESS ITEM - PORTO */}
            {/* <NanoFlex className={`addressItem ${this.state.selectedAddress == 1 ? "selectedAddress" : ""}`} flexDirection="column" justifyContent="flex-start" alignItems="flex-start" onClick={() => this.setState({ selectedAddress: 1 })}>
              <NanoFlex className="officeName" justifyContent="flex-start">
                <Type.p>
                  <b>{this.translation.homepage.officesBlock.oporto.name}</b>
                </Type.p>
              </NanoFlex>
              <NanoFlex className="officeAddress" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>{this.translation.homepage.officesBlock.oporto.address}</Type.p>
                <Type.p>{this.translation.homepage.officesBlock.oporto.addressExtraInfo}</Type.p>
                <Type.p>{this.translation.homepage.officesBlock.oporto.postalCode}</Type.p>
                <Type.p>{this.translation.homepage.officesBlock.oporto.country}</Type.p>
              </NanoFlex>
              <NanoFlex className="officeEmail" justifyContent="flex-start">
                <a href={`mailto:${this.translation.homepage.officesBlock.oporto.email}`}>
                  <Type.p>{this.translation.homepage.officesBlock.oporto.email}</Type.p>
                </a>
              </NanoFlex>
            </NanoFlex> */}

            {/* ADDRESS ITEM - BRASIL */}
            <NanoFlex className={`addressItem ${this.state.selectedAddress == 2 ? "selectedAddress" : ""}`} flexDirection="column" justifyContent="flex-start" alignItems="flex-start" onClick={() => this.setState({ selectedAddress: 2 })}>
              <NanoFlex className="officeName" justifyContent="flex-start">
                <Type.p>
                  <b>{this.translation.homepage.officesBlock.brazil.name}</b>
                </Type.p>
              </NanoFlex>
              <NanoFlex className="officeAddress" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.p>{this.translation.homepage.officesBlock.brazil.address}</Type.p>
                <Type.p>{this.translation.homepage.officesBlock.brazil.postalCode}</Type.p>
                <Type.p>{this.translation.homepage.officesBlock.brazil.country}</Type.p>
              </NanoFlex>
              <NanoFlex className="officePhone" justifyContent="flex-start">
                <Type.p>{this.translation.homepage.officesBlock.brazil.phone}</Type.p>
              </NanoFlex>
              <NanoFlex className="officeEmail" justifyContent="flex-start">
                <a href={`mailto:${this.translation.homepage.officesBlock.brazil.email}`}>
                  <Type.p>{this.translation.homepage.officesBlock.brazil.email}</Type.p>
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>

        <NanoFlex className="asideImage" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="imageWrapper">
            <img src={OfficesIMG} alt="Offices" />
          </NanoFlex>
        </NanoFlex>
      </StyledOfficesBlock>
    );
  }
}
